<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
    <div class=" mb-4">
        <h2 class="text-left" style="text-transform: capitalize;">Configuración</h2>
    </div>
    <div class="container-fluid p-0">
      <!-- <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Tarjeta</button> -->
    </div>
    <div class="my-4 mb-2">
      <!-- <h2 class="text-left">Mis solicitudes</h2> -->
    </div>
    
      <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-6 mb-3">
              <label for="">home_video</label> <br>
              <input type="file" id="file5" ref="file5" v-on:change="onFileSelected('home_video',5)" accept="video/*"/><br>
              <input required class="form-control" v-model="row.home_video" type="text" name="" id="">
              <video 
                v-if="row.home_video && !filename5"
                :src="'/' + row.home_video"
                class="video_home mt-3 d-block"
                controls
                ></video>
            </div>
            <div class="col-6 mb-3">
              <label for="">llama_ahora</label> 
              <input required class="form-control" v-model="row.llama_ahora" type="text" name="" id="">              
            </div>
            <div class="col-12 mb-3">
              <label for="">sobre_nosotros</label> 
              <!-- <b-form-textarea rows="8" required class="form-control" v-model="row.sobre_nosotros" type="text" name="" id=""></b-form-textarea>             -->
                <vue-editor class="form-control p-0 m-0" style="height:210px;" v-model="row.sobre_nosotros" :editorOptions="editorOptions"/>
            </div>
            
            <div class="col-6 mb-3">
              <!-- <label for="">contacto_email</label> 
              <input required class="form-control" v-model="row.contacto_email" type="text" name="" id=""> -->
              <label for="">otras recomendaciones</label> 
              <input required class="form-control" v-model="row.otras_recomendaciones" type="text" name="" id="">
            </div>
            <div class="col-6 mb-3">
              <label for="">otras recomendaciones descripción</label> 
              <input class="form-control" v-model="row.otras_recomendaciones_des" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <!-- <label for="">contacto_telefono</label>  -->
              <!-- <input required class="form-control" v-model="row.contacto_telefono" type="text" name="" id="">
              <label for="">contacto_whatsapp</label> 
              <input required class="form-control" v-model="row.contacto_whatsapp" type="text" name="" id="">
              <label for="">facebook</label> 
              <input required class="form-control" v-model="row.facebook" type="text" name="" id="">
              <label for="">youtube</label> 
              <input required class="form-control" v-model="row.youtube" type="text" name="" id=""> -->              
              <label for="">Carousel1_img</label> <br>
              <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('carousel1',1)"/><br>   
              <input required class="form-control" v-model="row.carusel1_img" type="text" name="" id="">        
                 
              <!-- <b-form-group >
                <b-form-file :placeholder="row.carusel1_img" required ref="file1" v-model="row.carusel1_img"></b-form-file>                
              </b-form-group> -->
            </div>
            <div class="col-6 mb-3">
              
              <!-- <label for="">Carousel2_img</label> <br>
              <b-form-group >
                <b-form-file :placeholder="row.carusel2_img" required ref="file2" v-model="row.carusel2_img"></b-form-file>                
              </b-form-group> -->
              <label for="">Carousel2_img</label> <br>
              <input type="file" id="file2" ref="file2" v-on:change="onFileSelected('carousel2',2)"/><br>   
              <input required class="form-control" v-model="row.carusel2_img" type="text" name="" id="">                  
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel3_img</label> <br>
              <input type="file" id="file3" ref="file3" v-on:change="onFileSelected('carousel3',3)"/><br>   
              <input required class="form-control" v-model="row.carusel3_img" type="text" name="" id="">
              <!-- <label for="">Carousel3_img</label> <br>
              <b-form-group >
                <b-form-file :placeholder="row.carusel3_img" required ref="file3" v-model="row.carusel3_img"></b-form-file>                
              </b-form-group>            -->

            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel4_img</label> <br>
              <input type="file" id="file4" ref="file4" v-on:change="onFileSelected('carousel4',4)"/><br>   
              <input required class="form-control" v-model="row.carusel4_img" type="text" name="" id="">
              <!-- <label for="">Carousel4_img</label> <br>
              <b-form-group >
                <b-form-file :placeholder="row.carusel4_img" required ref="file4" v-model="row.carusel4_img"></b-form-file>                
              </b-form-group>           -->
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel1_titulo</label> 
              <input required class="form-control" v-model="row.carusel1_titulo" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel1_titulo</label> 
              <input required class="form-control" v-model="row.carusel1_titulo" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel2_titulo</label> 
              <input  class="form-control" v-model="row.carusel2_titulo" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel3_titulo</label> 
              <input  class="form-control" v-model="row.carusel3_titulo" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel4_titulo</label> 
              <input  class="form-control" v-model="row.carusel4_titulo" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel1_descripción</label> 
              <input required class="form-control" v-model="row.carusel1_descripcion" type="text" name="" id="">             
            </div>
            <div class="col-6 mb-3">
               <label for="">Carousel2_descripción</label> 
              <input  class="form-control" v-model="row.carusel2_descripcion" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel3_descripción</label> 
              <input  class="form-control" v-model="row.carusel3_descripcion" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">Carousel4_descripción</label> 
              <input  class="form-control" v-model="row.carusel4_descripcion" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">texto_boton1</label> 
              <input required class="form-control" v-model="row.texto_boton1" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">texto_boton2</label> 
              <input  class="form-control" v-model="row.texto_boton2" type="text" name="" id="">                          
            </div>
            <div class="col-6 mb-3">
              <label for="">texto_boton3</label> 
              <input  class="form-control" v-model="row.texto_boton3" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">texto_boton4</label> 
              <input  class="form-control" v-model="row.texto_boton4" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">ruta_boton1</label> 
              <input required class="form-control" v-model="row.ruta_boton1" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">ruta_boton2</label> 
              <input  class="form-control" v-model="row.ruta_boton2" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">ruta_boton3</label> 
              <input  class="form-control" v-model="row.ruta_boton3" type="text" name="" id="">              
            </div>
            <div class="col-6 mb-3">
              <label for="">ruta_boton4</label> 
              <input  class="form-control" v-model="row.ruta_boton4" type="text" name="" id="">
            </div>
            <div class="col-6 mb-3">
              <label for="">Tipo de cambio</label> 
              <input step="any" class="form-control" v-model="row.tipo_cambio" type="number" name="" id="">
            </div>

          </div>

        <div class="row my-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary mx-1 float-right ml-3">Guardar</b-button>
            <!-- <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button> -->
          </div>
        </div>
      </form>

  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  components: {
    VueEditor
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      selected: null,
      editorOptions : {
        modules: {
          toolbar: false,
        },
      },
        categorias: [
          { id: 1, text: 'Categoría' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
        subcategorias: [
        ],
        Producto: [
          { id: 1, text: 'Producto' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
      perPage: 25,
      currentPage: 1,

      table: "tarjetas",
      modalText: 'Nueva Ficha',
      title: 'Configuracion',
      items: [],

      table_list: [
        // { id: 1 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 2 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 3 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 4 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 5 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
      ],
      row:{},
      categoria_id: 1,
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'titulo', label: 'Titulo', sortable: true, class: 'text-center' },
          { key: 'subcategoria', label: 'Categoría', sortable: true, class: 'text-center' },
          // { key: 'fecha_inicio', label: 'fecha inicio', sortable: true, class: 'text-center' },
          // { key: 'fecha_fin', label: 'fecha fin', sortable: true, class: 'text-center' },
          // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      file5: null,
      filename1: null,
      filename2: null,
      filename3: null,
      filename4: null,
      filename5: null,
    };
  },
  async mounted() {
    this.$isLoading(true)
    await this.getData();
    this.$isLoading(false)
  },
  methods: {
    onFileSelected(type, item){
      if(item==1){
        this.file1 = event.target.files[0];
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename1 = type + '_' + this.filename1+ '.jpg';
      }
      if(item==2){
        this.file2 = event.target.files[0];
        this.filename2 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename2 = type + '_' + this.filename2+ '.jpg';
      }
      if(item==3){
        this.file3 = event.target.files[0];
        this.filename3 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename3 = type + '_' + this.filename3+ '.jpg';
      }
      if(item==4){
        this.file4 = event.target.files[0];
        this.filename4 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename4 = type + '_' + this.filename4+ '.jpg';
      }
      if(item==5){
        this.file5 = event.target.files[0];

        if(this.file5){
          const ext = this.file5.name.split('.').pop();
          this.filename5 = Math.floor(Math.random() * 9999999999999 + 1);
          this.filename5 = type + '_' + this.filename5+ `.${ext}`;
        }
      }

      console.log(type,',',item)
    },    
    showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getSubcategories() {
      try {
        let res = await this.$store.dispatch("get", { path: 'subcategorias/getAllByCategory/' + this.categoria_id});
        console.log('------------->',res)
        if(res.length>0){
          this.subcategorias = res
        }
        else{
          this.subcategorias = []
        }
      } catch (error) {
        this.subcategorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path:  'configuracion/getAll'});
        //console.log(res)
        if(res.length>0){
          this.table_list = res
          this.row = this.table_list[0]
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.table_list = []
        this.totalRows = this.table_list.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async saveData() {
      try {
        let req
        //this.row.categoria_id = this.categoria_id
        //console.log(this.row)
        this.$isLoading(true)        
        let data = this.row
        let uploadImg
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            data.carusel1_img = '../img/' + this.filename1
          }
        }
        if(this.filename2!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename2 ,file: this.file2})
          if(uploadImg == true){
            console.log('----',uploadImg)
            data.carusel2_img = '../img/' + this.filename2
          }
        }
        if(this.filename3!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename3 ,file: this.file3})
          if(uploadImg == true){
            console.log('----',uploadImg)
            data.carusel3_img = '../img/' + this.filename3
          }
        }
        if(this.filename4!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename4 ,file: this.file4})
          if(uploadImg == true){
            console.log('----',uploadImg)
            data.carusel4_img = '../img/' + this.filename4
          }
        }
        if(this.filename5!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename5 ,file: this.file5})
          if(uploadImg == true){
            console.log('----', uploadImg)
            data.home_video = 'img/' + this.filename5
          }
        }
          let path = 'configuracion/modify/1'
          //console.log('path', path)
          delete this.row.id
          req = await this.$store.dispatch("put", {
            path: path,
            data: data
          });
        this.$isLoading(false)
       
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        console.log(error)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        //alert(error);
      }
    },
    ///////////////
    gotoTanks(){
      console.log('voy a ...')
      this.$router.push('/admin/gracias_solicitud')
    },

    async deleteItem(item) {
      try {
        console.log(item.id)
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }

        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Registro eliminado correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: "Error: " + error,
        });        
        console.log(error)
        //alert(error);
      }
    },
  },
};
</script>

<style>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.video_home{
  margin: auto;
  width: 200px;
}

</style>